.Header{
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgb(216, 216, 216);
}
.Header-h1{
    margin: 0;
    margin-left: 20px;
    text-align: center;
    font-family: "Mulish", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
}
.Locate-us-button{
    margin-right: 20px;
    width: 120px;
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 14px;
    justify-content: space-evenly;
    border: none;
    background-color: black;
    border: 1px solid white;
    color: white;
    border-radius: 20px;
    transition: 0.2s ease-in-out;
    font-family: sans-serif;
}
.Locate-us-svg{
    width: 18px;
    height: 18px;
    fill: white;
}
.Locate-us-button:hover{
    cursor: pointer;
    fill: black;
    background-color: rgb(152, 152, 152);
}

@media only screen and (max-width: 450px) {
    .Header{
        height: 70px;
    }
    .Header-h1{
        margin: 0;
        margin-left: 10px;
        font-size: 20px;
    }
    .Locate-us-button{
        font-size: 0;
        width: 32px;
        height: 30px;
        margin: 0;
        margin-right: 10px;
    }
}