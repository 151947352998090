.Footer{
    width: calc(100% - 40px);
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: aquamarine; */
}
.Footer-p{
    /* font-size: 20px; */
    font-size: clamp(1px, 0.5px + 5vw, 20px);
    font-family: "Mulish", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    color: rgb(0, 0, 0);
}
.Footer-span{
    color: white;
    background-color: black;
    padding: 10px;
    border-radius: 7px;
}
@media only screen and (max-width: 450px)
{
    .Footer{
        height: 100px;
    }
}